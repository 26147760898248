import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* -------------------------------------------------------------------------- */
/*                                  Partners                                  */
/* -------------------------------------------------------------------------- */

const partners = [
	{
		name: "Accenture",
		image: require('@/assets/coop_logos/accenture.png'),
		link: "https://www.accenture.com/at-de",
		id: ""
	},
	{
		name: "EY",
		image: require('@/assets/coop_logos/ey.png'),
		link: "https://www.ey.com",
		id: ""
	},
	{
		name: "ZEB",
		image: require('@/assets/coop_logos/zeb.png'),
		link: "https://zeb.at/",
		id: ""
	},
	{
		name: "Uniforce",
		image: require('@/assets/coop_logos/uniforce2.png'),
		link: "https://www.uniforce.at/",
		id: ""
	},
	{
		name: "Icons",
		image: require('@/assets/coop_logos/icons.svg'),
		link: "https://icons.at/",
		id: ""
	},
	{
		name: "Capgemini",
		image: require('@/assets/coop_logos/capgemini.png'),
		link: "https://www.capgemini.com/",
		id: ""
	},
	{
		name: "Happtiq",
		image: require('@/assets/coop_logos/happtiq.png'),
		link: "https://www.happtiq.com/",
		id: ""
	},
	{
		name: "JE Austria",
		image: require('@/assets/coop_logos/jeaustria.png'),
		link: "https://www.juniorenterprises.at/",
		id: ""
	},
	{
		name: "Grizzly",
		image: require('@/assets/coop_logos/Grizzly-Banner.png'),
		link: "https://www.grizzly.jobs?utm_source=lumos",
		id: ""
	},
	{
		name: 'Studo',
		image: require('@/assets/coop_logos/studo_logo_red.png'),
		link: 'https://studo.com#org=65219ee359',
	},
	{
		name: 'Fraunhofer',
		image: require('@/assets/coop_logos/fraunhofer.png'),
		link: 'https://www.fraunhofer.at/',
	},
];

/* -------------------------------------------------------------------------- */
/*                                 Experiences                                */
/* -------------------------------------------------------------------------- */

const experiences = [
	{

		title: {
			DE: '<span>Energie Reporting</span> für Intelligente Gebäude',
			EN: '<span>Energy Reporting</span> for Smart Buildings​',
		},
		text: {
			DE: 'Entwicklung einer Softwarelösung zur effizienten Analyse von Sensordaten aus gebäudetechnischen Anlagen. Das Tool soll den Arbeitsaufwand des Fachpersonals reduzieren und ermöglicht die Erstellung von Berichten, die klare Handlungsempfehlungen zur Energieeinsparung geben.',
			EN: 'Development of a software solution for efficiently analyzing sensor data from building technology systems. The tool is designed to reduce the workload of specialized personnel and enables the creation of reports that provide clear recommendations for action to save energy.',
		},
		image: require('@/assets/experience_icons/Siemens.png'),
		id: "1"
	},
	{
		title: {
			DE: '<span>Förderungs-Finder</span>​',
			EN: '<span>Grant Finder</span>',
		},
		text: {
			DE: 'Um die Notwendigkeit regelmäßiger Recherchen zu beseitigen, wurde eine Fördermitteldatenbank eingerichtet, die mit Hilfe eines Web-Crawlers regelmäßig aufgefüllt wird. Wenn neue relevante Fördermittel gefunden werden, wird eine Benachrichtigung per E-Mail verschickt.',
			EN: 'In order to eliminate the need for regular research, a funding database has been created that is regularly populated with the help of a web crawler. When new relevant subsidies are found, a notification e-mail is sent.​',
		},
		image: require('@/assets/experience_icons/collectiveenergy.png'),
		id: "2"
	},
	{
		title: {
			DE: '<span>Finanzielle Berechnungen</span> & Dashboard',
			EN: '<span>Financial Calculations</span> & Dashboard</span>​',
		},
		text: {
			DE: 'Übertragung von Daten mit Finanzberechnungen aus Microsoft Excel-Arbeitsblättern (z. B. GuV, CFS) in eine in Python programmierte Dashboard-Schnittstelle. Die Arbeitsblätter boten die Möglichkeit, zukünftige Daten unter Verwendung von Annahmen des Kunden zu berechnen (z. B. Wachstum, Abschreibung, Veränderungen der Vermögenswerte).',
			EN: 'Transferring of data from with financial calculations from Microsoft Excel worksheets (e.g., P&L, CFS), to a Python-coded dashboard interface. The worksheets offered the option to calculate future data using client provided assumptions (e.g., growth, depreciation, changes in assets).​',
		},
		image: require('@/assets/experience_icons/erste.png'),
		id: "3"
	},
	{
		title: {
			DE: '<span>Überwachungssystem</span> & Dashboard',
			EN: '<span>Monitoring System</span> & Dashboard',
		},
		text: {
			DE: 'Analyse der internen Verkaufs- und Kundendaten, Ermittlung von KPIs. Unser Team entwarf ein Überwachungssystem, das die Beteiligten proaktiv benachrichtigt, wenn Prozesse behindert werden. Außerdem entwickelten wir ein intuitives, interaktives grafisches Dashboard, das alle relevanten Informationen integriert.',
			EN: 'Analysis of internal sales and customer usage data, identifying KPIs. Our team designed a monitoring system that proactively alerts stakeholders when processes are impeded. Additionally, we developed an intuitive, interactive graphical dashboard that integrates all relevant information.​',
		},
		image: require('@/assets/experience_icons/leadbacker.png'),
		id: "4"
	},
	{
		title: {
			DE: 'pfpd',
			EN: 'ghdfg',
		},
		text: {
			DE: 'pfpd',
			EN: 'ghdfg',
		},
		image: require('@/assets/experience_icons/graph-up-arrow.svg'),
		id: "5"
	},
	{
		title: {
			DE: 'pfpd',
			EN: 'ghdfg',
		},
		text: {
			DE: 'pfpd',
			EN: 'ghdfg',
		},
		image: require('@/assets/experience_icons/graph-up-arrow.svg'),
		id: "6"
	},
	{
		title: {
			DE: 'pfpd',
			EN: 'ghdfg',
		},
		text: {
			DE: 'pfpd',
			EN: 'ghdfg',
		},
		image: require('@/assets/experience_icons/graph-up-arrow.svg'),
		id: "7"
	}
];



/* -------------------------------------------------------------------------- */
/*                               Department                                   */
/* -------------------------------------------------------------------------- */

const departments_de = [
	{
		id: "board",
		name: "Vorstand",
		description: "Der Vorstand von Lumos sorgt dafür, dass die gesamte Organisation eine gemeinsame Vision und Ausrichtung hat, damit wir alle gemeinsam vorankommen und wachsen können. Dazu organisieren und moderieren wir vereinsweite Treffen, entwickeln strategische Themen und übernehmen die Verantwortung für neue Projekte, die ganz Lumos betreffen, damit sich alle Abteilungen auf ihre Kernaufgaben und Stärken konzentrieren können."
	},
	{
		id: "marketing",
		name: "Marketing & Recruiting",
		description: "Ziel dieser Abteilung ist es, die Rekrutierungsphase zu planen und durchzuführen, um engagierte und kompetente neue Mitglieder zu finden, die zu Lumos passen. Darüber hinaus ist es das Ziel der Abteilung, eine konsistente Online- und Offline-Präsenz aufzubauen und zu pflegen. Weiters entwerfen wir unterstützende Materialien, die sowohl intern als auch extern verwendet werden."
	},
	{
		id: "sales",
		name: "Sales",
		description: "In der Vertriebsabteilung geht es um die Akquisition neuer und die Pflege aktueller Beziehungen zu unseren Partnern. Daher ist diese Abteilung die erste Anlaufstelle für externe Unternehmen und Organisationen. Dazu gehört auch, die Bedürfnisse unserer Partner zu ermitteln und dementsprechend potenzielle Projekte zu entwickeln, die unseren Mitgliedern nicht nur wertvolle Networking-Möglichkeiten bieten, sondern es ihnen auch ermöglichen, Erfahrungen in der Projektgestaltung zu sammeln."
	},
	{
		id: "it",
		name: "IT & Operations",
		description: "Unser Team IT & Ops ist für die gesamte IT-Infrastruktur von Lumos verantwortlich. Wir unterstützen unsere Lumos-Mitglieder bei IT-Themen, halten unsere Kommunikationskanäle am Laufen, arbeiten an Automatisierungen und kümmern uns um unsere Website. In IT & Ops legen wir Wert auf Zusammenarbeit, Entwicklung und Kommunikation."
	},
	{
		id: "academy",
		name: "Academy",
		description: "In der Lumos Academy kümmern wir uns um den Wissensaustausch und den Erwerb von Fähigkeiten innerhalb von Lumos. Die Abteilung organisiert Data Science Days, bei denen Lumos-Mitglieder und Gastredner ihr Fachwissen und ihre Erfahrungen in den mit Data Science verbundenen Bereichen teilen. Die Abteilung kuratiert auch den Lumos-Blog, in dem unsere Mitglieder aufschlussreiche Blog-Posts veröffentlichen, in denen sie über ihre Cases und persönlichen Projekte berichten. Neben diesen öffentlich zugänglichen Quellen gibt es noch viele weitere spannende Lernmöglichkeiten, die von der Academy innerhalb von Lumos organisiert werden."
	},
	{
		id: "legal",
		name: "Finance & Legal",
		description: "Die Finanz- und Rechtsabteilung stellt zwei sehr wichtige Funktionen unserer Organisation dar. Insbesondere die Buchhaltung, die Finanzplanung und die Sicherstellung der rechtlichen Grundlagen sind von größter Bedeutung. Die Abteilung ist aber auch für den Umgang mit allen externen Stakeholdern, wie z.B. Banken, dem Finanzamt oder Wirtschaftsprüfern, zuständig."
	},
	{
		id: "hr",
		name: "Human Resources",
		description: "In der Personalabteilung dreht sich alles um die Menschen und die Kultur bei Lumos. Unser Hauptziel ist es, den Menschen bei Lumos zu helfen, sich zu entwickeln und zu wachsen. Außerdem wollen wir die Kultur und die Beziehungen in der Organisation pflegen. Vom Onboarding neuer Mitglieder bis zur Organisation von Zusammenkünften versuchen wir immer, für unsere Lumis da zu sein und sicherzustellen, dass sie eine großartige Erfahrung bei Lumos machen."
	},
	{
		id: "adv",
		name: "Advisors",
		description: "Die Advisors sind eine Gruppe erfahrener Lumis, die den Vorstand und die Departments mit ihrem Fachwissen unterstützen. Sie helfen in verschiedenen Bereichen oder bei bestimmten Projekten, je nach ihren Fähigkeiten und ihrer bisherigen Erfahrung bei Lumos. Sie tragen dazu bei, das Wissen und die Erkenntnisse der Organisation zu bewahren und stellen sicher, dass Lumos sich immer weiterentwickelt."
	}
];

const departments_en = [
	{
		id: "board",
		name: "Board",
		description: "The board at Lumos ensures that the entire organization has a shared vision and direction, so we can all move forward and grow together. For this, we organize and moderate association-wide meetings, develop strategic topics, and take responsibility for new projects concerning all of Lumos, so that all departments can focus on their core tasks and strengths."
	},
	{
		id: "marketing",
		name: "Marketing & Recruiting",
		description: "The goal of this department is to plan and execute the recruiting phase to find committed, competent new members who fit Lumos. Furthermore, the department's goal is to establish and maintain a consistent online and offline presence. In addition, we design supporting materials that are used both internally and externally."
	},
	{
		id: "sales",
		name: "Sales",
		description: "The sales department is all about acquiring new as well as maintaining old relations with external partners. Therefore this department is the first point of contact. This also includes discussing the needs of our partners and developing potential projects accordingly, providing not only valuable networking opportunities to our members but also allowing them to gain experience when it comes to project design."
	},
	{
		id: "it",
		name: "IT & Operations",
		description: "Our Team IT & Ops is responsible for the whole IT infrastructure in Lumos. We support our Lumos Members with IT Topics, keep our Communication Channels running, work on Automations and take care of our Website. In IT & Ops we emphasize Collaboration, Development, and Communication."
	},
	{
		id: "academy",
		name: "Academy",
		description: "At Lumos Academy, we take care of the knowledge exchange and skills acquisition within Lumos. The department organizes Data Science Days, where Lumos members and guest speakers share their expertise and experience in the fields related to Data Science. It also curates the Lumos Blog where our members publish insightful blog posts telling about cases they have done, as well as their personal projects. Alongside these publically available sources, there are much more exciting learning opportunities organized by Academy within Lumos."
	},
	{
		id: "legal",
		name: "Finance & Legal",
		description: "The Finance & Legal Department represents two very important functions of our organization. In particular, accounting, financial planning, and ensuring a legal basis are of utmost relevance. However, the department is also responsible for dealing with all external stakeholders, such as banks, the tax office, or auditors."
	},
	{
		id: "hr",
		name: "Human Resources",
		description: "The HR department is all about the people & culture at Lumos. Our main goal is to help the people at Lumos thrive and develop themselves. Additionally, we aim to nourish the culture and relationships in the organization. From onboarding new members to organizing get-togethers we always try to be there for our Lumis and make sure they have a great Lumos experience."
	}
	,
	{
		id: "adv",
		name: "Advisors",
		description: "The Advisors are a group of experienced Lumis that support the board and the departments with their expertise. They provide help in different areas or particular projects according to their skills and previous experience in Lumos. They contribute to preserving the knowledge and learnings of the organization and ensure that Lumos is always moving forward."
	}
];


/* -------------------------------------------------------------------------- */
/*                          Export & State Management                         */
/* -------------------------------------------------------------------------- */

export default new Vuex.Store({
	state: {

		// test stuff
		testy: {
			title: {
				EN: 'english',
				DE: 'deutsch'
			},
			subtitle: {
				EN: 'hello my man this is some text',
				DE: 'hallo mein mann hier ist etwa texte'
			}

		},
		

		// actual stuff
		language: 'EN',
		// BCP 47 language tag
		lang: {
			DE: 'de-DE',
			EN: 'en-US'
		},

		navTitles: [
			{
				DE: 'Über Uns',
				EN: 'About',
			},
			{
				DE: 'Services',
				EN: 'Services',
			},
			{
				DE: 'Bewerben',
				EN: 'Apply',
			},
			{
				DE: 'Blog',
				EN: 'Blog',
			},
			{
				DE: 'Team',
				EN: 'Team',
			},
			{
				DE: 'Events',
				EN: 'Events',
			},
		],

		landing: {
			title: {
				DE: 'Data Science,<br>Not Rocket Science',
				EN: 'Data Science,<br>Not Rocket Science',
			},
			subtitle: {
				DE: 'Lumos bietet studentische Beratung und individuelle, state-of-the-art Lösungen im Bereich Data Science an.',
				EN: 'Lumos offers Data Science consulting and customized state-of-the-art solutions, designed by students with diverse technological backgrounds.',
			},
			button1: {
				DE: 'Services',
				EN: 'Services',
			},
			button2: {
				DE: 'Kontakt',
				EN: 'Contact',
			},
			button3: {
				DE: 'FAQ',
				EN: 'FAQ',
			},
			button_apply_now: {
				DE: 'Jetzt Mitglied werden!',
				EN: 'Become a member now!'
			},
			event_view_button: {
				DE: 'Alle Events anzeigen',
				EN: 'View All Events',
			},
		},
		apexTitle: {
			title_1: {
				DE: 'Studienrichtung',
				EN: 'Discipline of study',
			},
			title_2: {
				DE: 'Universitäten',
				EN: 'Universities'
			}
		},
		whyLumos: {
			title: {
				DE: 'Warum <span>Lumos?</span>',
				EN: 'Why <span>Lumos?</span>',
			},
			text: {
				DE: 'Lumos bietet engagierten und talentierten Data Science Studenten einen Rahmen, sich weiterzubilden und an professionellen Aufgabenstellungen zu wachsen. Gesammelte Berufserfahrungen in den Bereichen Software Engineering, FinTech, Predictive Analytics, Business Intelligence, Economics, Psychologie, & Statistik ermöglicht uns, auf eine diverse Bandbreite an Fähigkeiten zurückzugreifen, wodurch wir unseren Projektpartnern individuelle und innovative State-of-the-Art-Lösungen anbieten können.',
				EN: 'Lumos provides an environment for motivated and talented Data Science students to extend their knowledge of new technologies and improve their hard skills by working on real business cases. Our backgrounds in Software Engineering, FinTech, Predictive Analytics, Business Intelligence, Economics, Psychology, & Statistics enable us to draw on a diverse set of skills to help supply our business partners with new and innovative data solutions.',
			},
		},
		services: {
			title: {
				DE: 'Unsere <span>Leistungen</span>',
				EN: 'Our <span>Services</span>',
			},
			subtitle: {
				DE: 'Viele Unternehmen kennen den Wert und das Potential ihrer Daten oft nicht oder haben Schwierigkeiten diese in ihre Geschäftsprozesse sinnvoll miteinzubeziehen. Daher bietet Lumos Unternehmen und Organisationen individuell angepasste Lösungen im Bereich Data Science an. Hier fokussieren wir uns auf folgende Bereiche:',
				EN: 'Many businesses do not realize the capabilities of their data or struggle to make use of them in their business processes. Therefore, Lumos offers tailored solutions to the needs of each client in the following fields: ',
			},
			sections: [
				{
					title: {
						DE: 'Machine Learning',
						EN: 'Machine Learning',
					},
					text: {
						DE: 'Machine Learning bildet den Oberbegriff für dynamische Algorithmen, die mit Hilfe von künstlicher Intelligenz eigenständig Lösungen für Probleme und Prozessoptimierung finden können. Diese Algorithmen werden mit aufgezeichneten Daten trainiert und auf die jeweilige Anwendung mittels Tests optimiert. Lumos bietet individuell konfigurierte Machine Learning Lösungen an, die in diversen Bereichen eingesetzt werden können, um Produkte und Leistungen eines Unternehmens zu verbessern.',
						EN: 'Machine Learning is a term for dynamic algorithms that use artificial intelligence to create solutions to problems and optimize current processes independently. These models are trained with data based on historic events and adjusted to the problem and optimized through testing. The trained model can then be used to improve products and services, find solutions to existing problems, or help with predictions.',
					},
					image: {
						DE: require('@/assets/Illustration1.svg'),
						EN: require('@/assets/Illustration1.svg'),
					},
					orientation: {
						DE: 'regular',
						EN: 'regular',
					}
				},
				{
					title: {
						DE: 'Data Analytics',
						EN: 'Data Analytics',
					},
					text: {
						DE: 'Data Analytics beschäftigt sich mit der Untersuchung großer Datenmengen, um verdeckte Zusammenhänge und Trends zu entdecken. Es kommen moderne statistische Modelle zum Einsatz, die Muster in Daten ausfindig machen und basierend darauf Schlüsse für zukünftige Ereignisse ziehen. Die daraus resultierenden Erkenntnisse unterstützen Unternehmen bei Entscheidungsfindungsprozessen. Hierbei helfen wir nicht nur bei der technischen Umsetzung solcher Modelle, sondern arbeiten auch mit Ihnen an der Ideenfindung, in welchen Bereichen Ihres Unternehmens solche Modelle hilfreich sein können.',
						EN: 'Data Analytics is the process of exploring large amounts of data to uncover  hidden correlations and trends. Statistical models are used to find patterns in recorded data and draw conclusions about future events. Consequently, the resulting insights support companies in data driven decision making. We not only help our partners to implement and customize such models, but also work together on finding new and creative ways for making use of them in their businesses.',

					},
					image: {
						DE: require('@/assets/Illustration2.svg'),
						EN: require('@/assets/Illustration2.svg'),
					},
					orientation: {
						DE: 'reverse',
						EN: 'reverse',
					}
				},
				{
					// title: {
					// 	DE: 'Data Analysis',
					// 	EN: 'Data Analysis',
					// },
					// text: {
					// 	DE: 'Das Ziel der Datenanalyse ist, fundierte Informationen und Strukturen mit Hilfe der Transformation, Modellierung und Analyse von Daten aufzudecken. Dabei kommen statistische Methoden und moderne Analyseverfahren zum Einsatz, um unternehmensrelevante Einblicke in Geschäftsprozesse zu erhalten. Lumos hilft Unternehmen den, oft noch unentdeckten, Wert ihrer Daten zu erfassen um nachhaltig Nutzen daraus zu ziehen.',
					// 	EN: 'The aim of data analysis is to find relevant information and structures by extracting, cleaning and modeling data. Lumos uses different analysis tools and modern analytical methods to gain important insights in your data. We thereby help our partners to understand their data’s real value with the derived knowledge.',
					// },
					title: {
						DE: 'Business Intelligence',
						EN: 'Business Intelligence',
					},
					text:  {
						DE: 'Business Intelligence (BI) bezieht sich auf die Technologien und Praktiken, die zur Erfassung, Integration, Analyse und Präsentation von Geschäftsinformationen verwendet werden. Diese helfen außerdem  Unternehmen dabei, datengestützte Entscheidungen zu treffen. BI umfasst die zahlreichen Tools, mit denen Rohdaten in aussagekräftige Erkenntnisse umgewandelt werden können. Unsere Fähigkeit, Lösungspipelines zu entwickeln, kann Sie dabei unterstützen, Entscheidungen besser zu treffen, Ihre betriebliche Effizienz zu optimieren, und Markttrends zu erkennen.',
						EN: 'Business Intelligence (BI) refers to the technologies and practices used to capture, integrate, analyze and present business information. It also helps companies to make data-driven decisions. BI encompasses the many tools used to transform raw data into meaningful insights. Our ability to develop solution pipelines can help you make better decisions, optimize operational efficiency, and identify market trends.',
					},
					image: {
						DE: require('@/assets/Illustration3.svg'),
						EN: require('@/assets/Illustration3.svg'),
					},
					orientation: {
						DE: 'regular',
						EN: 'regular',
					},
				}
			]
		},
		blogBox: {
			title: {
				DE: "Kennst du schon den Lumos Blog?",
				EN: "Already know the Lumos Blog?",
			},
			subtitle: {
				DE: "Gewinne einen Einblick in die Themen, die wir bei Lumos untersuchen.",
				EN: "Read about topics that we at Lumos are interested in.",
			},
			button: {
				DE: "Jetzt Lesen",
				EN: "Read Now",
			},
		},
		lumosCoop: {
			title: {
				DE: "Unsere <span>Partner</span>",
				EN: "Our <span>Partners</span>",
			},
			subtitle: {
				DE: "Die jahrelange Erfahrung und fachliche Expertise unserer Partner unterstützt uns, unseren Wissensschatz im Bereich Data Science Consulting kontinuierlich zu erweitern. Technische Tools unserer Partner ermöglichen es uns, qualitativ-hochwertige Data Science Cases zu entwicklen und bereit zu stellen. ",
				EN: "The vast experience and technical expertise of our partners supports us in a sustainable development of more broad and in depth Data Science knowledge. The technical tools of our partners make it possible to develop and deploy high-quality Data Science applications.",
			},
			partners: {
				DE: partners,
				EN: partners,
			},

		},
		experiences: {
			experiences: {
				DE: experiences,
				EN: experiences,
			},
			title: {
				DE: "Unsere <span>Projekte</span>",
				EN: "Our <span>Projects</span>",
			},
		},
		team: {
			all_members_cont: {
				DE: [], // to be loaded from contentful
				EN: [],
			},
			title: {
				DE: "Unser <span>Team</span>",
				EN: "Our <span>Team</span>",
			},
			teamdescription: {
				DE: "Unser Team setzt sich aus ungefähr 20-30 aktiven Mitgliedern zusammen. Wir studieren in unterschiedlichen Studiengängen aus den Bereichen Informatik, Wirtschaftswissenschaften, Mathematik und Psychologie und an mehreren Universitäten in Wien. Was uns dabei vereint ist die Passion für Data Science. In gemeinsamen Projekten, über Workshops und durch Vorträge lernen wir voneinander, um unser Wissen darüber vertiefen zu können. Neben dem eigentlichen Vereinsziel gibt es natürlich auch regelmäßig gemeinsame Social-Events, die unseren Zusammenhalt stärken.",
				EN: "Our team consists of about 20-30 active members. We study in different university programs in the fields of computer science, economics, mathematics and psychology and at several universities in Vienna. What unites us is our passion for Data Science. In joint projects, through workshops and during our Data Science days we learn from each other to deepen our knowledge about this topic. In addition to the actual goal of the association, there are of course regular social events that strengthen us as a team."
			}
		},

		contact: {
			title: {
				DE: 'Kontaktieren Sie uns',
				EN: 'Reach Out To Us',
			},
			text: {
				DE: 'Sie wollen mehr über Lumos erfahren, haben Fragen zu unseren Leistungen oder sind an einer Zusammenarbeit interessiert? Dann kontaktieren Sie uns, und wir kümmern uns gerne um Ihr Anliegen.',
				EN: 'You want to know more about Lumos and our services or have questions regarding a collaboration? Then do not hesitate to contact us!',
			},
			namePlaceholder: {
				DE: 'Voller Name...',
				EN: 'Full Name...',
			},
			emailPlaceholder: {
				DE: 'Email...',
				EN: 'Email...',
			},
			messagePlaceholder: {
				DE: 'Ihre Nachricht...',
				EN: 'Your Message...',
			},
			button: [
				{
					DE: 'Senden',
					EN: 'Send',
				},
				{
					DE: 'Vielen Dank für die Anfrage!',
					EN: 'Thank you for reaching out!',
				},
				{
					DE: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder kontakten Sie uns per e-mail.',
					EN: 'An error occurred. Please try again later or contact us via e-mail.',
				}
			],
			buttonText: {
				DE: 'Sendet...',
				EN: 'Sending...',
			},
			buttonValidationError: {
				DE: 'Fehler: Stellen Sie sicher, dass alle Felder ausgefüllt sind.',
				EN: 'Error: Ensure that all fields are filled out.'
			},
			buttonEmailError: {
				DE: 'Fehler: Stellen Sie sicher, dass die E-Mail eine gültige Adresse ist.',
				EN: 'Error: Make sure that the email is a valid address.',
			}
		},
		events: {
			title: {
				DE: 'Bevorstehende <span>Events</span>',
				EN: 'Upcoming <span>Events</span>',
			},
			subtitle: {
				DE: 'Wir bieten regelmäßig Workshops und Vorträge zu aktuellen Themen im Bereich Data Science an. Hierbei haben wir sowohl Einsteiger als auch Fortgeschrittene im Blick. Die Teilnahme an unseren Events ist kostenlos und für alle Interessierten offen.',
				EN: 'We regularly offer workshops and lectures on current topics in the field of Data Science. Our events address beginners as well as advanced users. Participation in our events is free of charge and open to all interested parties.',
			},
			pastEventsTitle: {
				DE: 'Vergangene <span>Events</span>',
				EN: 'Past <span>Events</span>',
			},
			noEvents: {
				DE: 'Aktuell sind keine Events geplant. Schau bald wieder vorbei!',
				EN: 'Currently there are no events planned. Come back again soon!',
			},
			events: {
				DE: [], // to be loaded from contentful
				EN: [],
			},
			loadingStatus: {
				DE: true,
				EN: true,
			}
		},
		cookieMessages: [
			{
				DE: "Diese Website verwendet Cookies um die präsentierten Inhalte besser an Sie anzupassen.",
				EN: "This website uses cookies to ensure that you get the best experience on our website.",
			},
			{
				DE: "Stimmen Sie der Verwendung von Cookies zu?",
				EN: "Do you accept the usage of cookies?"
			},
			{
				DE: "Zustimmen",
				EN: "Accept",
			},
			{
				DE: "Ignorieren",
				EN: "Ignore me",
			}
		],
		departments: {
			DE: departments_de,
			EN: departments_en
		},

		faq: [
			{
				'question': {
					DE: "Ist Lumos eine Firma?",
					EN: "Is Lumos a company?",
				},
				'answer': {
					DE: "Nein, wir sind eine Junior Enterprise. Laut Definition ist das eine ehrenamtliche Organisation, die gegen Bezahlung oder pro bono Beratung und Services für Firmen anbietet. Wir sind die erste und einzige Junior Enterprise in Österreich, die sich auf Data Science spezialisiert. Somit arbeiten wir mit Technikunternehmen zusammen und bearbeiten Cases, die Data Science, Software Development und Technikstrategie beinhalten.",
					EN: "No, we are a junior enterprise. By definition, this is a volunteer organization that provides consulting and services to companies for a fee or pro bono. We are the first and only Junior Enterprise in Austria that specializes in Data Science. Thus, we work with tech companies and handle cases that include data science, software development and tech strategy.",
				},
			},
			{
				'question': {
					DE: "Wie viele Mitglieder hat Lumos?",
					EN: "How many members does Lumos have?",
				},
				'answer': {
					DE: "Lumos hat in der Regel 20-30 Mitglieder während des Semesters, welche alle aktive StudentInnen sind. Wir kommen aus verschiedenen natur- und geisteswissenschaftlichen Studienrichtungen. Außerdem besuchen wir unterschiedliche Universitäten, die du auf unserer Teamseite sehen kannst.",
					EN: "Lumos usually has 20-30 members during the semester, all of whom are active students with diverse backgrounds. We come from different science disciplines and economics. We also attend different universities which you can view on our team page."
				},
			},
			{ // merged with: What are the benefits of being a member of Lumos?

				'question': {
					DE: 'Warum sollte ich Mitglied von Lumos werden?',
					EN: "Why should I join Lumos?",
				},
				'answer': {
					DE: 'Mitglied bei Lumos zu sein, bietet viele Vorteile: Intern kannst du bei Vorträgen, Challenges und Workshops deine Data Science Skills verbessern, erste Führungserfahrungen sammeln & dich mit den anderen Mitgliedern über aktuelle Technologien austauschen. Extern kannst du dein theoretisches Wissen in verschiedenen Bereichen in der Praxis anwenden, von unseren exklusiven Partnerschaften profitieren & erste Kontakte zu zukünftigen Arbeitgebern knüpfen. Dein Engagement bei Lumos wird sich positiv auf deinen Lebenslauf auswirken und - was am wichtigsten ist - du wirst Freunde fürs Leben finden.',
					EN: "Being a member of Lumos offers many benefits: Internally you can improve your Data Science skills at lectures, challenges and workshops, gain first leadership experiences, & exchange ideas about current technologies with the other members. Externally you can apply your theoretical knowledge in practice in different areas, benefit from our exclusive partnerships, & make first contacts with future employers. Your involvement in Lumos will have a positive impact on your resume and, most importantly, you will make friends for life."
				},
			},
			{
				'question': {
					DE: "Was muss ich können um mich zu bewerben?",
					EN: "What do I need to be able to do to apply?",
				},
				'answer': {
					DE: "Du kannst aus allen möglichen Disziplinen kommen, wir sind stolz darauf einen sehr diversen Pool an Mitgliedern zu haben. Coding-Skills und Data Science Kenntnisse genießen bei uns ein hohes Ansehen, aber es reicht auch schon die Motivation sich diese anzueignen, denn bei uns bekommt man ausgezeichnete Möglichkeiten dies zu tun.",
					EN: "You can come from all kinds of disciplines, we are proud to have a very diverse pool of members. Coding skills and Data Science knowledge are highly regarded by us, but just having the motivation to acquire them is enough, because you will get excellent opportunities to do so with us.",
				},
			},
			{
				'question': {
					DE: "Wie werde ich ein Mitglied bei Lumos?",
					EN: "What are the steps to apply for Lumos?",
				},
				'answer': {
					DE: "Während der Bewerbungsphase am Beginn eines Semesters kannst du in ein paar wenigen Schritten deine Bewerbung absenden. Wenn du gut zu Lumos passt bekommst du eine Einladung zum Assessment Center. Wenn das erfolgreich abgeschlossen ist bekommst du die Möglichkeit bei uns reinzuschnuppern und wenn es dir gefällt wirst du offizielles Mitglied.",
					EN: "During the application phase at the beginning of a semester, you can send your application in a few steps. If you are a good fit for Lumos you will receive an invitation for an assessment center. If that's successful, you'll get a chance to check us out and if you like it, you'll become an official member."
				},
			},
			{
				'question': {
					DE: "Was erwartet mich beim Assessment Center?",
					EN: "What can I expect at the Assessment Center?",
				},
				'answer': {
					DE: "Wenn deine Bewerbung erfolgreich ist, wirst du zu einem Assessment-Center eingeladen, welches etwa acht Stunden dauert. Dort erwarten dich Data Science-Fallstudien, um deine Fähigkeiten zu überprüfen.",
					EN: "If your application is successful, you will be invited to an assessment center that lasts approximately eight hours. There, you can expect Data Science case studies to assess your skills."
				},
			},
			{ // question below is a merge from the old "Wieso soll ich bei Lumos mitmachen?" & "Welche Vorteile habe ich als Mitglied bei Lumos?"
				'question': {
					DE: "Wie schaut der Alltag bei Lumos aus?",
					EN: "What is everyday life like at Lumos?",
				},
				'answer': {
					DE: "Lumos besteht aus drei Säulen: der internen Arbeit im Verein, der externen Arbeit mit anderen Unternehmen und das gemeinsame Lernen durch die Academy. Als Mitglied bei Lumos arbeitest du in einem Department an dem Erhalt und der Weiterentwicklung des Vereins mit. Bei Projekten mit externen Unternehmen kannst du deine Skills anwenden und real-life Applikationen konzipieren und umsetzen. Eines unserer Departments ist die Academy, die dem Wissensaustausch dient. Mit Teach-Talks, Workshops und gemeinsamen Coding Sessions lernen wir voneinander und geben jedem Mitglied die Chance, sein/ihr Wissen zu teilen und zu erweitern.",
					EN: "Lumos consists of three pillars: the internal work in the association, the external work with other companies, and the shared learning through the Academy. As a member of Lumos, you work in a department to maintain and develop the community. In projects with external companies you can apply your skills and design and implement real-life applications. One of our departments is the Academy, which serves the exchange of knowledge. With teach-talks, workshops and joint coding sessions, we learn from each other and give every member the chance to share and expand his/her knowledge.",
				},
			},
			{
				'question': {
					DE: "Wie viel Zeit sollte ich für Lumos einplanen?",
					EN: "How much time would I have to invest in Lumos?",
				},
				'answer': {
					DE: "Der Arbeitsaufwand, der als aktives Mitglied bei Lumos anfällt, variiert von Woche zu Woche. \nWir haben uns auf kein fixes Arbeitspensum festgelegt, jedoch intern ausgemacht, dass man mit ca. 8-10 Stunden pro Woche rechnen kann. Aber wie so alles im Leben: Umso mehr Zeit man rein investiert, umso größer ist dein persönlicher Benefit von deiner Zeit bei Lumos.",
					EN: "The amount of work required to be an active member at Lumos varies from week to week. We have not set a fixed workload, but internally we have agreed that you can expect about 8-10 hours per week. But like everything in life: The more time you put in, the greater your personal benefit from your time at Lumos.",
				},
			},
			{
				'question': {
					DE: "Wie viel Geld würde ich bei Lumos verdienen?",
					EN: "How much would I earn at Lumos?",
				},
				'answer': {
					DE: "Lumos ist ein Verein und somit verdient man als Mitglied per se nichts. Wenn man erfolgreich an einem Projekt mit einer externen Firma arbeitet, wird man dafür aber entschädigt. Für die interne Arbeit im Verein wirst du mit viel Erfahrung und einem coolen Team belohnt. Geld, das der Verein selbst einnimmt, kommt außerdem den Mitgliedern wieder zugute.",
					EN: "Lumos is a non-profit organization and therefore you don't earn anything as a member per se. However, if you successfully work on a project with an external company, you will be compensated for that. For internal work in the association you are rewarded with a lot of experience and a cool team. Money that the association itself takes in also goes back to the members.",
				},
			},
			{
				'question': {
					DE: 'Wie kann ich bei Lumos einem Department beitreten?',
					EN: 'How can I join a department at Lumos?',
				},
				'answer': {
					DE: 'Sobald du ein volles Mitglied bei Lumos bist, kannst du deine 3 bevorzugten Departments aussuchen, denen du beitreten möchtest. Bei Lumos gibt es keine bestimmten Positionen, die besetzt werden müssen, wie in einem echten Unternehmen. So können die Departments flexibler sein, was die Anzahl der Personen angeht, die sie aufnehmen, je nach Interessen der Leute. Einige Departments benötigen jedoch mehr Mitglieder als andere, so dass dies bei der Einteilung der neuen Mitglieder ebenfalls berücksichtigt wird.',
					EN: 'Once you become a full member of Lumos, you will be able to choose your top 3 preferences for which department to join. Lumos does not have specific positions that need to be filled like in a true company, which allows departments to be more flexible in how many people they take in based on people\'s interests. However some departments still generally need more members than others, so when allocating new members this will also be taken into account.'
				},
			},
		],
		applyPage: {
			main_title: [
				{
					DE: "Wir suchen ",
					EN: "We are looking for ",
				},
				{
					DE: "dich!",
					EN: "you!",
				}
			],
			application_description: {
				DE: "Du begeisterst dich für Daten und neue Technologien und willst dir neben theoretischem Wissen auch praktische Erfahrung aneignen? Dann bewirb dich und werde Teil eines einzigartigen Studentennetzwerks im Bereich Data Science.",
				EN: "Are you passionate about data and new technologies and want to gain practical experience in addition to theoretical knowledge? Then apply and become part of a unique student network in the field of Data Science.",
			},
			application_deadline_description: [
				{
					DE: "Bewirb dich noch bis",
					EN: "Apply until the",
				},
				{
					DE: "und nutze diese einzigartige Chance Teil von Lumos zu werden! Unser Assessment Center ist für den 15. oder 16. März angesetzt. Wir bitten dich, diesen Termin freizuhalten.",
					EN: "and take this unique chance to become part of Lumos! Our Assessment Center is scheduled for March of 15th or 16th. We kindly ask you to keep this date free."
				},
			],

			application_deadline: {
				DE: "9. März",
				EN: "9th of March",
			},

			button: {
				DE: "Jetzt bewerben!",
				EN: "Apply now!",
			},

			questions_button: {
				DE: "Hast du noch Fragen?",
				EN: "Still have questions?",
			},

			why_part: [
				{
					DE: "Warum",
					EN: 'Why',
				},
				{
					DE: 'du',
					EN: 'you',
				},
				{
					DE: "Teil werden solltest!",
					EN: "should be a part!",
				},
				{
					DE: "Bei Lumos hast du nicht nur die Möglichkeit dein Verständnis von Daten sowohl theoretisch als auch praktisch zu erweitern, sondern wir bieten dir auch die Chance Einblicke in die verschiedenen Bereiche unserer Junior Enterprise zu bekommen und dich aktiv an der Mitgestaltung und dem Aufbau zu beteiligen.",
					EN: "At Lumos, you will not only have the opportunity to expand your understanding of data both theoretically and practically, but we also offer you the chance to gain insight into the different areas of our Junior Enterprise and actively participate in helping to shape and build it.",
				},
			],
			network_part: [
				{
					DE: "Netzwerk",
					EN: "Network",
				},
				{
					DE: "Lumos ist ein aktives und soziales Netzwerk aus Studierenden und Alumni in dem die Stimme eines jeden Mitglieds respektiert und auch jede Idee wertgeschätzt wird. Wir kommen einmal pro Woche für unser Weekly Meeting zusammen, in dem wir Updates aus den verschiedenen Teilbereichen unserer Junior Enterprise teilen und Neuigkeiten zu aktuellen Themen rund um Data Science austauschen und diskutieren.",
					EN: "Lumos is an active and social network of students and alumni where every member's voice is respected and every idea is valued. We come together once a week for our Weekly Meeting, where we share updates from different subsections of our Junior Enterprise and share and discuss news on current topics related to Data Science.",
				}
			],
			education_part: [
				{
					DE: "Weiterbildung",
					EN: "Education",
				},
				{
					DE: "Weiterbildung steht bei uns an oberster Stelle. Data Science ist ein sich dynamisch ändernder Bereich. Deshalb ist es uns wichtig unsere Mitglieder stets am aktuellen Stand der neuen Technologien zu halten. Durch regelmäßige Vorträge, Workshops und Challenges fördern wir den Wissensaustausch unter Mitgliedern und trainieren unsere Fähigkeiten.",
					EN: "Continuing education is a top priority for us. Data Science is a dynamically changing field. Therefore, it is important for us to keep our members up to date with new technologies. Through regular lectures, workshops and challenges, we promote the exchange of knowledge among members and train our skills.",
				}
			],
			project_part: [
				{
					DE: "Projekte",
					EN: "Projects",
				},
				{
					DE: "Sowohl durch spannende interne Projekte als auch Projekte mit externen Stakeholdern hast du die Möglichkeit dein Wissen an echten Problemen anzuwenden und relevante Praxiserfahrung sammeln. Da unsere Mitglieder in den verschiedensten Bereichen Erfahrung haben, können wir unsere Projektpartner auch in zahlreichen Situation unterstützen.",
					EN: "Through both exciting internal projects and projects with external stakeholders, you have the opportunity to apply your knowledge to real problems and gain relevant practical experience. Since our members have experience in a wide range of areas, we can also support our project partners in numerous situations.",
				}
			],
			wesearch_part: [
				{
					DE: 'Wonach',
					EN: 'Who',
				},
				{
					DE: 'suchen',
					EN: 'we',
				},
				{
					DE: 'wir',
					EN: 'are looking for',
				},
				{
					DE: "Wir suchen motivierte und engagierte Studierende, die bereits erste Data Science Kenntnisse haben oder diese erlernen wollen und die vorhaben, ihr theoretisches Wissen auch durch praktische Erfahrung zu komplementieren. Am besten besitzt du eine analytische und strategische Denkweise, kannst bereits ein wenig programmieren und hast ein Verständnis von Statistik und dem Verarbeiten von Daten.",
					EN: "We are looking for motivated and dedicated students who already have some Data Science knowledge or want to learn it and who plan to complement their theoretical knowledge with practical experience. Ideally, you have an analytical and strategic mindset, can already do some programming and have an understanding of statistics and the processing of data.",
				},
				{
					DE: "Vor allem suchen wir aber Teamplayer, auf die wir uns verlassen können und die unser Interesse teilen. Die weiteren Skills können mit genug Motivation und von uns empfohlenem Lernmaterial schnell nachgeholt werden.",
					EN: "Above all, we are looking for team players who we can rely on and who share our interest. The other skills can be caught up quickly with enough motivation and learning material recommended by us.",
				}
			],
			application_closed_part: [
				{
					DE: "Unser Bewerbungsfenster ist derzeit geschlossen",
					EN: "Our application window is currently closed",
				},
				{
					DE: "Wir haben zyklische Bewerbunszeiten, welche immer am Anfang eines Uni-Semesters stattfinden.",
					EN: "We have cyclical application periods, which always take place at the beginning of a university semester",
				}
			],
			application_window_closed_button: {
				DE: "Bewerbungsfenster geschlossen",
				EN: "Application window closed",
			},
			faq_button_text: {
				DE: 'Fragen zur Bewerbung?',
				EN: 'FAQs about applying',
			},
			faq_subtitle: {
				DE: 'um dem Lumos-Team beizutreten',
				EN: 'for joining the Lumos team',
			},
			reminder: {
				DE: 'Möchten Sie eine E-Mail-Erinnerung erhalten, wenn unsere Anwendung wieder geöffnet ist?',
				EN: 'Would you like to receive an E-mail reminder when our application is open again?',
			},

		},
	},
	mutations: {
		switchLanguage(state, language) {
			state.language = language
		},
		setEvents(state, events) {
			state.events.events.EN = events;
			state.events.events.DE = events;
		},
		setMembers(state, members) {
			state.team.all_members_cont.EN = members;
			state.team.all_members_cont.DE = members;
		},
		
		setLoadingStatus(state, status) {
			state.events.loadingStatus.EN = status;
			state.events.loadingStatus.DE = status;
		}
	},
	actions: {
		async fetchEvents({commit}) {
			commit('setLoadingStatus', true)
			try {
				await fetch(
					'api/content?type=all&contentType=event', {
						method: 'GET'
					}
				)
					.then((response) => response.json())
					.then((responseData) => {
						commit('setEvents', responseData)
						commit('setLoadingStatus', false)
					})
				
			} catch (error) {
				console.log(error)
				console.log('Error fetching events')
			}
		},
		
		async fetchMembers({commit}) {
			commit('setLoadingStatus', true)
			try {
				await fetch(
					'api/content?type=all&contentType=teamMember', {
						method: 'GET'
					}
				)
					.then((response) => response.json())
					.then((responseData) => {
						commit('setMembers', responseData)
						commit('setLoadingStatus', false)
					})
			} catch (error) {
				console.log(error)
				console.log('Error fetching members')
			}
		},
	},
	modules: {},
})


